@font-face {
  font-family: "Lato";
  src: local("Lato"), url(./fonts/Lato/Lato-Light.ttf) format("truetype");
  font-weight: 500;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: "verdana"; */
  font-family: "Lato";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

.loading-box {
  animation: rotate-animation 6s infinite linear;
  background-image: url("./images/Marockly_Spinning_LogoNoM_f.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  /* background-blend-mode: multiply; */
  display: block;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 100px;
  height: 100px;
  transition: opacity 1s;
  z-index: 1000;
}

#error-img {
  background-image: url("./images/metagora_error_tile.png");
  /* background-color: blue; */
}

@keyframes rotate-animation {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

* {
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  display: none;
}

/* .amplify-scrollview::-webkit-scrollbar-corner {
  background-color: rgb(0, 0, 0);
  background: black;
} */
.data-value {
  font-weight: bold;
  color: white;
}
.negative {
  color: rgba(255, 80, 0, 0.9);
  font-weight: bold;
}

.positive {
  color: rgba(4, 255, 4, 0.9);
  font-weight: bold;
}

.neutral {
  color: rgba(255, 255, 255, 0.5);
}

.rs-sidenav-item-active,
.rs-dropdown-item-active {
  background-color: #26363e !important;
}

button:hover,
a:hover {
  background-color: #2950c2 !important;
  color: white !important;
}

.rs-dropdown-menu {
  background-color: #26363e !important;
  color: white !important;
}
.rs-table-row-header,
.rs-tag,
.rs-picker-input {
  background-color: black;
  color: white;
}

.rs-sidenav-subtle .rs-dropdown-item:hover {
  background-color: black;
  border: none;
}

.rs-table-row-expanded,
.rs-picker-input,
.rs-picker-menu,
.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-btn,
.rs-picker-input .rs-picker-toggle {
  background-color: black !important;
  border: none !important;
  color: white !important;
}

.rs-toggle-checked .rs-toggle-presentation {
  background-color: rgb(5, 106, 149) !important;
}
.rs-table-cell-group-fixed-left,
.rs-toggle-presentation {
  background-color: black !important;
}

.rs-panel-header {
  padding: 0;
}

.rs-navbar-item,
.rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
  height: 30px;
  /* padding: 10; */
}

#historical_pnl,
#trader-dashboard {
  font-size: 0.9em;
}

.rs-table-cell {
  background-color: black;
  border: none;
}

@media only screen and (max-width: 600px) {
  .no_mobile {
    display: none;
  }
}
