@import url("./../../styles/variables.css");

.container {
  padding: 0.5rem;
  border-radius: 10px;
}

.actions {
  padding: 0.5rem;
}

.cardItem {
  padding: 10px;
  border-radius: 10px;
  background-color: #141518;
  border: 1px solid #161616;
  width: 100%;
}
