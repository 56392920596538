@import url("../../../styles/variables.css");

/* Manager Card */

.managerContainer {
  padding: 0.8rem 1.8rem;
}

.managersCard {
  padding: 0.9rem;
  border-radius: 10px;
  border: 3px solid var(--bg-color);
  background-color: #141518;
  height: 50vh;
}

.cardItem {
  padding: 1rem;
  border-radius: 10px;
  background-color: #141518;
}

.cardItemChart {
  padding: 1rem;
  border-radius: 10px;
}

.cardItemTransparent {
  padding: 1rem;
  border-radius: 10px;
  border: 2px solid #1b1d24;
}

.managersChartCard {
  padding: 0.9rem;
  border-radius: 10px;
  border: 3px solid var(--bg-color);
  background-color: #141518;
  height: 50vh;
}

.viewDisplayName {
  animation: bounce 0.3s ease-out alternate;
}

@keyframes bounce {
  100% {
    transform: translateX(15px);
  }
}

.portfolioDetailTabs {
  max-height: 80lvh;
  min-height: 54lvh;
  overflow: auto;
  padding-right: 10px;
}

.managersItemsContainer {
  max-height: 90lvh;
  overflow: auto;
}

.modalManagersItemsContainer {
  max-height: 80lvh;
  overflow: auto;
}

.portfolioItemsContainer {
  max-height: 80lvh;
  overflow: auto;
}

.portfolioViewItemsContainer {
  max-height: 80lvh;
  overflow: auto;
}

/* Portfolio Carousel */

.portfolioCarouselCard {
  padding: 0.9rem;
  border-radius: 10px;
  border: 3px solid var(--bg-color);
  background-color: #141518;
}
.portfolioCarousel {
  overflow: auto;
}

.portfolioCarouselItem {
  padding: 1rem;
  border-radius: 10px;
  background-color: #1b1d24;
  min-width: 300px;
  max-height: 120px;
  cursor: pointer;
}

.cardManagerItem {
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 10px;
  background-color: var(--bg-color);
}

.cardPortfolioItem {
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 10px;
  background-color: var(--bg-color);
  cursor: pointer;
}

.cardItemAction {
  padding: 0.5rem 1rem 0 1rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--bg-color);
}

.itemsContainer {
  padding-right: 10px;
  max-height: 78vh;
  overflow: auto;
}

.portfolioDetail {
  border-radius: 10px;
  background-color: var(--bg-color);
}

.portfolioItemSelected {
  background: linear-gradient(
    200deg,
    rgba(1, 41, 6, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  ) !important;
}

@keyframes animate-in-and-out {
  entry 0% {
    opacity: 0;
    transform: translateY(100%);
  }
  entry 100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.managerPortfolioList,
.managerPortfolioSettingsDesktop {
  display: block;
}

.portfoliosModalContainer {
  padding: 0px;
}

.selectPortfolioButton,
.managerPortfolioSettingsMobile {
  display: none;
}

.statusFilter {
  display: block;
}
.statusFilterFunnel {
  display: none;
}

@media screen and (max-width: 991px) {
  .managerPortfolioList,
  .managerPortfolioSettingsDesktop {
    display: none;
  }

  .managerContainer {
    padding: 0.8rem 10px;
  }

  .selectPortfolioButton,
  .managerPortfolioSettingsMobile {
    display: block;
  }
}

@media screen and (max-width: 1301px) {
  .statusFilter {
    display: none;
  }
  .statusFilterFunnel {
    display: block;
  }
}
