.cardItem {
  padding: 1rem;
  border-radius: 10px;
  background-color: #141518;
  border: 1px solid #161616;
}

.countCard {
  background: linear-gradient(
    200deg,
    rgb(17, 24, 51) 0%,
    rgba(18, 135, 0, 0.1) 100%
  );
}

.cardItemTransparent {
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #161616;
}

.steps {
  vertical-align: top;
  width: 200px;
  display: inline-table;
}

[data-testid="form-control-wrapper"] {
  width: 100%;
  display: block;
}
